<template>
  <div class="home-page">
    <main-jumbotron />
    <b-container class="my-3" id="why_us">
      <b-row class="flex-align">
        <b-col sm="6">
          <Articles :heading="heading" :paraghText="why" />
        </b-col>
        <b-col sm="6">
          <b-img
            class="w-100"
            :src="require('@/assets/imgs/illustrations/1.png')"
          />
        </b-col>
      </b-row>
    </b-container>
    <h2 class="font-bold underlined text-center my-3" id="features">
      {{ $t("agrivezita_features") }}
    </h2>

    <section class="bg-light">
      <b-container>
        <b-row class="flex-align">
          <b-col sm="6" class="my-3">
            <Articles :heading="consult" :paraghText="consultation" />
          </b-col>
          <b-col sm="6" class="my-3 text-center">
            <b-img
              class="w-70"
              :src="require('@/assets/imgs/illustrations/2.png')"
            />
          </b-col>
          <b-col sm="6" class="my-3 text-center d-none-small">
            <b-img
              class="w-70"
              :src="require('@/assets/imgs/illustrations/3.png')"
            />
          </b-col>
          <b-col sm="6" class="my-3">
            <Articles :heading="advice" :paraghText="advice_txt" />
          </b-col>
          <b-col sm="6" class="my-3 text-center d-none-large">
            <b-img
              class="w-70"
              :src="require('@/assets/imgs/illustrations/3.png')"
            />
          </b-col>
          <b-col sm="6" class="my-3">
            <Articles :heading="article" :paraghText="article_txt" />
          </b-col>
          <b-col sm="6" class="my-3 text-center">
            <b-img
              class="w-70"
              :src="require('@/assets/imgs/illustrations/4.png')"
            />
          </b-col>
          <b-col sm="6" class="my-3 text-center d-none-small">
            <b-img
              class="w-70"
              :src="require('@/assets/imgs/illustrations/5.png')"
            />
          </b-col>
          <b-col sm="6" class="my-3">
            <Articles :heading="booking" :paraghText="appointment_txt" />
          </b-col>
          <b-col sm="6" class="my-3 text-center d-none-large">
            <b-img
              class="w-70"
              :src="require('@/assets/imgs/illustrations/5.png')"
            />
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="my-3">
      <b-container>
        <b-row class="flex-align">
          <b-col sm="6" class="my-3">
            <Articles :heading="download" :paraghText="download_txt" />
            <section class="main_jumbotron__actions d-flex">
              <a
                class="color-black font-bold"
                href="https://play.google.com/store/apps/details?id=com.agrivizeta.user"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h6>{{ $t("user_app") }}</h6>
                <img width="140px" src="@/assets/imgs/google.png" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.agrivizeta.expert"
                class="mx-2 color-black font-bold"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h6>{{ $t("expert_app") }}</h6>
                <img width="140px" src="@/assets/imgs/google.png" />
              </a>
            </section>
          </b-col>
          <b-col sm="6" class="my-3 text-center">
            <b-img
              class="w-70"
              :src="require('@/assets/imgs/illustrations/6.png')"
            />
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Home",
  components: {
    // WhyAgriVezita: () => import("@/components/WhyAgriVezita"),
    Articles: () => import("@/components/Articles")
  },
  data() {
    return {
      orderOverlay: false,
      services: false,
      work: false,
      subscribe: false,
      store: false,
      heading: this.$t("why_agrivezita"),
      why: "",
      consult: this.$t("consult_agrivezita"),
      consultation: "",
      advice: this.$t("agrivezita_advice"),
      advice_txt: "",
      article: this.$t("agrivezita_article"),
      article_txt: "",
      booking: this.$t("agrivezita_booking"),
      appointment_txt: "",
      download: this.$t("agrivezita_download"),
      download_txt: ""
    };
  },
  computed: {
    ...mapGetters(["GlobalSettings", "settings"])
  },
  watch: {
    GlobalSettings: {
      handler() {
        this.why = this.settings("why_agrivezita");
        this.consultation = this.settings("consult_agrivezita");
        this.advice_txt = this.settings("agrivezita_advice");
        this.article_txt = this.settings("agrivezita_article");
        this.appointment_txt = this.settings("agrivezita_booking");
        this.download_txt = this.settings("agrivezita_download");
      },
      immediate: true
    }
  }
};
</script>
